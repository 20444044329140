import { createRouter, createWebHistory } from 'vue-router'
import axios from "axios";
const routes = [
    {
    path:'/',
    name:'home',
    component:()=>import('@/components/LoginHandle.vue')
  },
  {
        name: "TableHandle",
        path: "/tablehandle",
        component:()=> import("@/components/TableHandle.vue"),
      },
      {
        name:'ReportAll',
        path:'/reportall',
        component:()=>import('@/components/ReportAll.vue')
      }
]
const router = createRouter({
  mode:'hash',
  history: createWebHistory(process.env.BASE_URL),
  routes
});
//路由守卫
router.beforeEach(async (to, from,next) => {
  console.log(to,from);
  // eslint-disable-next-line no-debugger
  // debugger;
    //判断是否存在指定cookie存在则回登陆界面
    if(to.name=="home"){
      to.params.isNewEnter = true;
      next();
      return false;
    }
    await axios({
      method: "get",
      url: "/apis/router.php",
    }).then(res=>{
      // next()为默认路由指向
      // next({path:'/xx'})等同于this.$router.pusu()方法
      if(res.data==true){
        console.log('路由守卫验证通过');
        if(to.name=="home"){
          next({name:'TableHandle'});
        }else{
          next();
      }
      }else{
        to.params.isNewEnter = true;
        console.log('没有cookie的验证失败,回到登陆界面吧');
        next({name:'home'});
      }
    });
  // 返回 false 以取消导航
})

export default router
