import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
// import echarts from 'echarts'
const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(ElementPlus);
// app.use(echarts);
// app.use(axios);

app.config.globalProperties.$axios = axios;
app.mount('#app');
